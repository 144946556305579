import React from 'react';

//
// const tryRequire = (path) => {
//   try {
//    return require(`${path}`);
//   } catch (err) {
//    return null;
//   }
// };

class Main extends React.Component {
  getQuote = (e) => {
    e.preventDefault();
    this.props.getQuote();
  }

  optionLabels = (stepNumber) => {
    const step       = this.props.steps[stepNumber];
    const details    = this.props.estimates.sections[step];
    const options    = details.options;

    return options.map((option, index) =>
      <li className={"option " + (Number(this.props.option_for_step[stepNumber]) === Number(index) ? "selected" : "") } key={index} onClick={ (e) => {
        this.props.setOptionForStep(stepNumber, index);
      }}>
        {option}
      </li>
    );
  }

  options = (stepNumber) => {
    const step       = this.props.steps[stepNumber];
    const details    = this.props.estimates.sections[step];
    const options    = details.options;
    var subtitle = ""
    const detailsDetails = details.details;
    var optionClassName = "";
    if (detailsDetails !== undefined) {
      const option = options[this.props.option_for_step[stepNumber]];
      optionClassName = option.replace(/\s/g, '_').toLocaleLowerCase();
      const optionDetails = detailsDetails[option];
      if (optionDetails !== undefined) {
        subtitle   = optionDetails.subtitle;
      }
    }
    if (options !== undefined) {
      return (
        <div className={"slider_container " + optionClassName}>
          <input type="range" min="0" max={options.length - 1} value={this.props.option_for_step[stepNumber]} className={"slider " + step.replace(/\s/g, '_')+"_options"} id="myRange" step="1" onChange={ (e) => {
            this.props.setOptionForStep(stepNumber, e.target.value);
          } }></input>
          <ul className="range-labels">
            {this.optionLabels(stepNumber)}
          </ul>
          <div className="subtitle">{subtitle}</div>
        </div>
      );
    } else {
      return
    }
  }

  answers = (stepNumber) => {
    const step       = this.props.steps[stepNumber];
    const details    = this.props.estimates.sections[step];
    const answers    = details.answers;

    if (answers !== undefined) {
      return answers.map((answer, index) =>
      <div className={"pill " + (this.props.answer_for_step[stepNumber] === index ? "selected" : "") + " " + answer.replace(/\s/g, '_')} key={index} onClick={() => {
        this.props.setAnswerForStep(stepNumber, index);
        this.props.setCategoryForStep(stepNumber, undefined)}
      }>
        <div className="icon" ></div>
        <div className="answer" >{answer}</div>
      </div>
      );
    } else {
      return
    }
  }

  question = (stepNumber) => {
    const step     = this.props.steps[stepNumber];
    const details  = this.props.estimates.sections[step];
    const question = details.question;

    if (question !== undefined) {
      return <div className={"question " + (question.replace(/\s/g, "_"))}>{question}</div>
    }

    return;
  }

  instructions = (stepNumber) => {
    const step         = this.props.steps[stepNumber];
    const details      = this.props.estimates.sections[step];
    const question     = details.question;
    const instructions = details.instructions;

    if (instructions !== undefined) {
      return <div className={"instructions " + (question.replace(/\s/g, "_")+"_instructions")}>{instructions}</div>
    }

    return;
  }

  currentDescription = (stepNumber) => {
    const section      = this.props.estimates.sections[this.props.steps[stepNumber]];

    const answers      = section.answers;
    const descriptions = section.descriptions;
    const answerNumber = this.props.answer_for_step[stepNumber]
    var answer         = undefined;
    if (answerNumber !== undefined) {
      answer       = answers[answerNumber];
    }

    var description = undefined;
    if (answer !== undefined && descriptions !== undefined) {
      description = descriptions[answer];
    } else {
      return;
    }

    return (
      <div className="answer_summary">
        <div>{description}</div>
      </div>
    )
  }

  categories = (stepNumber) => {
    const step       = this.props.steps[stepNumber];
    const details    = this.props.estimates.sections[step];
    const answers    = details.answers;
    const categories = details.categories;

    if (categories === undefined) {
      return
    }

    const answer = answers[this.props.answer_for_step[stepNumber]];

    const category = categories[answer];
    if (category === undefined) {
      return
    }

    return category.map((value, index) =>
      <div key={index} className={"option " + (this.props.category_for_step[stepNumber] === index ? "selected" : "")} onClick={() => {
        this.props.setCategoryForStep(stepNumber, index)}
      }>{value}</div>
    );
  }

  itemSubtitle = (stepNumber, key, value) => {
    // const a = this.props.estimates;
    const step       = this.props.steps[stepNumber];
    const details    = this.props.estimates.sections[step];
    if (details === undefined) {
      return;
    }
    const items      = details.items;
    if (items === undefined) {
      return;
    }

    const itemDetails = items[key];
    if (itemDetails === undefined) {
      return;
    }

    var subtitle = itemDetails["subtitle"];
    const detailsForProductType = itemDetails[this.props.product_type]
    if (detailsForProductType !== undefined) {
      if (detailsForProductType.subtitle !== undefined) {
        subtitle = detailsForProductType.subtitle;
      }
    }

    if (subtitle === undefined) {
      return;
    }

    return(
      <div className="subtitle">{subtitle}</div>
    );
  }

  items = (stepNumber) => {
    const step       = this.props.steps[stepNumber];
    const details    = this.props.estimates.sections[step];
    const items      = details.items;

    if (items === undefined) {
      return
    }

    return Object.entries(items).map(([key, value]) =>
      <div key={key} className={"item " +(
        this.props.items_for_step[stepNumber].has(key) ?
          "selected" :
          "")
      } onClick={(e) => {
        // TODO - why is this getting called twice ?
        if (this.props.items_for_step[stepNumber].has(key)) {
          this.props.removeItemFromStep(stepNumber, key);
        } else {
          this.props.addItemForStep(stepNumber, key);
        }
      }}>
        {key}
        <div className="range">
          ${this.props.itemRange(stepNumber, key)}
        </div>

        {this.itemSubtitle(stepNumber, key, value)}
      </div>
    );
  }

  similar = (stepNumber) => {
    const sections = this.props.estimates.sections;
    if (sections === undefined) {
      return
    }

    const step       = this.props.steps[stepNumber];
    if (step === undefined) {
      return;
    }

    const details    = this.props.estimates.sections[step];
    if (details === undefined) {
      return;
    }

    const similar = details["similar"];
    if (similar === undefined) {

      return;
    }

    const answers    = details.answers;
    const categoriesForAnswers = details.categories;

    if (categoriesForAnswers === undefined) {
      return
    }

    const answer = answers[this.props.answer_for_step[stepNumber]];
    const similarForAnswer = similar[answer];

    if (similarForAnswer === undefined) {
      return;
    }

    const categories = categoriesForAnswers[answer];
    if (categories === undefined) {
      return
    }

    const category = categories[this.props.category_for_step[stepNumber]];
    if (category === undefined) {
      return;
    }

    const similarForCategory = similarForAnswer[category];
    if (similarForCategory === undefined) {
      return;
    }

    return (
      <a href="#similar" onClick = {(e) => {
        this.props.showSimilar(stepNumber);
      }}>Select similar projects for a more accurate estimate.</a>
    )
  }

  next = (stepNumber) => {
    const next_step = stepNumber + 1;
    if ((stepNumber === 0 || stepNumber === 1) && this.props.answer_for_step[stepNumber] === undefined) {
      return(
        <div className="next">
          <a href={"#" + this.props.steps[next_step]} onClick={(e) => {
            this.props.setStep(next_step)}
          }><div className="white">Skip to:</div> {this.props.steps[next_step]}</a>
        </div>
      );
    }
    if (this.props.steps.length > next_step) {
      return(
        <div className="next">
          <a href={"#" + this.props.steps[next_step]} onClick={(e) => {
            this.props.setStep(next_step)}
          }><div className="white">Next:</div> {this.props.steps[next_step]}</a>
        </div>
      );
    } else {
      return(
        <div className="finished">
          <a href={"#" + this.props.steps[next_step]} onClick={(e) => {
            e.preventDefault();
            this.getQuote(e);
            }
          }><div className="white">Finished!</div> Start Project</a>
        </div>
      );
    }
  }

  step = (stepNumber) => {
    return (
      <div className={"stage " + (stepNumber === this.props.step ? "current" : "")} key={stepNumber}>
        <div className="questions">
          {this.question(stepNumber)}
          {this.instructions(stepNumber)}
          <div className="answers">
            {this.answers(stepNumber)}
          </div>
          {this.currentDescription(stepNumber)}
          <div className="categories">
            {this.categories(stepNumber)}
          </div>
            <div className="similar">
              {this.similar(stepNumber)}
            </div>
          <div className="items">
            {this.items(stepNumber)}
          </div>
          <div className="options">
            {this.options(stepNumber)}
          </div>
          {this.next(stepNumber)}
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="main">
        { this.props.steps.map((step, index) => {
          return this.step(index)
        })
        }
      </div>
    )
  }
}

export default Main;
