import React from 'react';

class Footer extends React.Component {
  getQuote = (e) => {
    e.preventDefault();
    this.props.getQuote();
  }

  shareQuote = (e) => {
    e.preventDefault();
    this.props.share();
  }

  estimate = () => {
    if (this.props.minCost === undefined && !this.props.rfqRequired) {
      return (
        <div className="cost">$0</div>
      );
    }

    if (this.props.rfqRequired) {
      if (this.props.product_type === "Aerospace") { // FIXME - hard coded constants :-/
        return (
          <div className="cost">$$$</div>
        );
      } else {
        return (
          <div className="cost">$$</div>
        );
      }
    }

    return (
      <div className="cost">${this.props.minCost}+</div>
    );
  }

  render() {
    return (
      <footer>
        <div className="title">Project Cost Estimator</div>
        <div className="estimate">
          {this.estimate()}
          <div className="currency">USD</div>
        </div>
        <div className="quote">
          <div className="get_quote"><a href="#get_quote" onClick={ (e) => {
            // e.preventDefault();
            this.getQuote(e);
          }
          }>Get Quote</a></div>
          <div className="save"><a href="#share" onClick={ (e) => {
            this.shareQuote(e);
          }}>Share</a></div>
          <div id="apple-sign-in-button"></div>
          <div id="apple-sign-out-button"></div>
          <div className="disclaimer">* Estimates are general, request a quote for formal pricing.</div>
        </div>
      </footer>
    );
  }
}

export default Footer;
