import React from 'react';

class Header extends React.Component {
  stage = (e) => {
    e.preventDefault();
    this.props.setStep("stage");
  }

  steps = () => {
    return this.props.steps.map((step, index) =>
      <li key={index}><a href={"#" + step} className={(this.props.step === index ? "active " : " ") + (this.props.step > index ? "visited" : "")} onClick={() => {this.props.setStep(index)}}><b><span className="number">{index + 1}</span>. {step}</b></a></li>
    );
  }

  render() {
    return (
      <header>
        <div className="est_logo">
          <a href="https://silsync.com">
            <img src="silsync-bare.png" alt="SILSYNC" />
          </a>
        </div>
        <ul id="nav">
          {this.steps()}
        </ul>
      </header>
    )
  }
}

export default Header;
