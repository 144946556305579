import React from 'react';



class Dialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      note: 'Check out my new project estimate from SILSYNC!',
      projectName: ''
    };
  }

  share = () => {
    if (!this.props.share) {
      return;
    }

    return (
      <div className="shareEstimate">
        <h1 className="shareEstimate">Share Your Estimate</h1>
        <div className="shareSummary">Share a link to your new project estimate, or save it for later!</div>
        <form onSubmit={this.shareEstimate}>
          <label>
            <input placeholder="Name" type="text" autoFocus name={this.state.name} onChange={this.changeName} />
          </label>
          <label>
            <input placeholder="Email" type="email" name="email" value={this.state.email} onChange={this.changeEmail} />
          </label>
          <textarea value={this.state.note} onChange={this.noteChanged} />
          <input type="submit" value="Share" />
        </form>
      </div>
    );
  }

  noteChanged = (event) => {
    this.setState({note: event.target.value});
  }

  changeName = (event) => {
    this.setState({name: event.target.value});
  }

  changeEmail = (event) => {
    this.setState({email: event.target.value});
  }

  changeProjectName = (event) => {
    this.setState({projectName: event.target.value});
  }

  sendRFQ = (event) => {
    event.preventDefault();

    if (this.state.email.length === 0) {
      alert("You must enter an email address");
      return;
    }

    this.props.sendRFQ(this.state.name, this.state.email, this.state.projectName);
  }

  shareEstimate = (event) => {
    event.preventDefault();

    if (this.state.email.length === 0) {
      alert("You must enter an email address");
      return;
    }

    this.props.shareEstimate(this.state.name, this.state.email, this.state.note);
  }

  getQuote = () => {
    if (!this.props.getQuote) {
      return;
    }

    return (
      <div className="getQuote">
        <h1 className="getQuote">Get Started</h1>
        <div className="quoteSummary">Bring your project to life, talk to a team member at SILSYNC!</div>
        <form onSubmit={this.sendRFQ}>
          <label>
            <input placeholder="Name" type="text" name={this.state.name} autoFocus onChange={this.changeName} />
          </label>
          <label>
            <input placeholder="Email" type="email" name="email" value={this.state.email} onChange={this.changeEmail} />
          </label>
          <label>
            <input placeholder="Project Name" type="text" name={this.state.projectName} value={this.state.projectName} onChange={this.changeProjectName} />
          </label>

          <input type="submit" value="Start Project" />
        </form>
      </div>
    );
  }

  thankYou = () => {
    if (!this.props.thankYou) {
      return;
    }

    return (
      <div className="thankYou">
        <img src="loading.gif" alt="Loading..." />
      </div>
    );
  }

  render() {
    return (
      <dialog className={this.props.showDialog ? "visible" : "hidden"}>
        <div onClick={() => {
          this.props.hideDialog();
      }}><div className="close">Close</div></div>

      {this.share()}
      {this.getQuote()}
      {this.thankYou()}
      </dialog>
    )
  }
}

export default Dialog;
