import React from 'react';
// import ReactDOM from 'react-dom';

import './App.css';
import Header from './header.js'
import Main from './main.js'
import Footer from './footer.js'
import Dialog from './dialog.js'
import GitInfo from 'react-git-info/macro'
import { v4 as uuidv4 } from 'uuid';

const gitInfo = GitInfo();

// import Amplify, { Auth } from 'aws-amplify';

const estimates = require('./silsync-estimator.json');

class App extends React.Component {
  defaultOptionForStep = () => {
    const option_for_step = Array(estimates.steps.length).fill(undefined);
    for (var stepNumber=0;stepNumber<estimates.steps.length;stepNumber++) {
      const step          = estimates.steps[stepNumber];
      const details       = estimates.sections[step];
      const options       = details.options
      const defaultOption = details.defaultOption;
      if (defaultOption !== undefined) {
        option_for_step[stepNumber] = options.indexOf(defaultOption);
      }
    }

    return option_for_step;
  }

  constructor(props) {
    super(props);

    this.bootup = true;

    const answer_for_step = Array(estimates.steps.length).fill(undefined);
    const similar_for_step = Array(estimates.steps.length).fill(undefined);
    const category_for_step = Array(estimates.steps.length).fill(undefined);
    const items_for_step = Array(estimates.steps.length);
    for (var i=0;i<estimates.steps.length;i++) {
      // tempting but we can't just fill() because it creates a single instance of Set() and puts it everywhere
      items_for_step[i] = new Set(); // tempting also to just use an Array here
    }

    this.state = {
      showDialog: false,
      showSimilar: false,
      showLogin: false,
      step: 0,
      answer_for_step: answer_for_step,
      option_for_step: this.defaultOptionForStep(),
      product_type: undefined,
      category_for_step: category_for_step,
      items_for_step: items_for_step,
      similar_for_step: similar_for_step,
      minCost: undefined,
      minHours: undefined,
      rfqRequired: false,
      gitHash: gitInfo.commit.hash,
    };
  }

  load = () => {
    const uuid = window.location.pathname.replace("/", "");
    if (uuid.length > 0) {
      const url = "https://hsg0762l5l.execute-api.us-east-1.amazonaws.com/dev/estimates/" + uuid;
      window.fetch(url, {
        // method: "GET",
        mode: "cors",
        headers: {
          'Content-Type': 'application/json'
        }
      }).then((result) => {
        result.json().then((data) => {
          const message = data.message;

          if (message === undefined) {
            return
          }

          const source = message.Item;
          if (source === undefined) {
            return;
          }

// TODO / FIXME - do this programatically instead of hard-coding it like this..
// TODO / FIXME - note that this will break if used with a previous version of the RFQ system
          var newState = {};

          if (source.gitHash !== this.state.gitHash) {
            alert("Warning: this estimate was made from:\n" + source.gitHash + "\nbut the current estimator is:\n" + this.state.gitHash + "\nchanges may have occurred in pricing and options.");
          }

          Object.keys(source).forEach((item, i) => {
            // these are the state elements we don't want to restore
            if (["timestamp", "gitHash", "showDialog", "share", "getQuote", "thankYou"].indexOf(item) >= 0) {
              return;
            }
            if (typeof(source[item]) !== "object") {
              newState[item] = source[item];
            }
          });

          const answer_for_step   = Array(estimates.steps.length).fill(undefined);
          const similar_for_step  = Array(estimates.steps.length).fill(undefined);
          const category_for_step = Array(estimates.steps.length).fill(undefined);
          const option_for_step   = this.defaultOptionForStep();

          const items_for_step = Array(estimates.steps.length);

          for (var step=0;step<estimates.steps.length;step++) {
            answer_for_step[step]    = source.answer_for_step[step]   === null ? undefined : source.answer_for_step[step];
            similar_for_step[step]   = source.similar_for_step[step]  === null ? undefined : source.similar_for_step[step];
            category_for_step[step]  = source.category_for_step[step] === null ? undefined : source.category_for_step[step];
            option_for_step[step]    = source.option_for_step[step]   === null ? undefined : source.option_for_step[step];
            items_for_step[step] = new Set(source.items_for_step[step]); // tempting also to just use an Array here
          }

          newState["answer_for_step"]   = answer_for_step;
          newState["similar_for_step"]  = similar_for_step;
          newState["category_for_step"] = category_for_step;
          newState["option_for_step"]   = option_for_step;
          newState["items_for_step"]    = items_for_step;

          this.setState(newState);
        });
      }, (result) => {
        // console.log(result);
      });
    }
  }

  save = () => {
    if (this.bootup) {
      this.bootup = false;
      return;
    }

    var statePackage = JSON.parse(JSON.stringify(this.state));

    for (var i=0;i<this.state.items_for_step.length;i++) {
      statePackage.items_for_step[i] = Array.from(this.state.items_for_step[i]);
    }

    window.fetch("https://hsg0762l5l.execute-api.us-east-1.amazonaws.com/dev/estimates", {
      method: "PUT",
      mode: "cors",
      headers: {
      },
      body: JSON.stringify(statePackage)
    }).then((result) => {

    }, (result) => {

    });
  }

  showThankYou = () => {
    this.setState({
      thankYou: true,
      share: false,
      getQuote: false
    });

    document.location.href = "https://silsync.com/thanks";
  }

  getQuote = () => {
    this.setState({getQuote: true})

    this.save();
    this.showDialog();
  }

  share = () => {
    this.setState({share: true})
    this.save();
    this.showDialog();
  }

  sendRFQ = (name, email, projectName) => {
    const uuid = window.location.pathname.replace("/", "");

    window.fetch("https://hsg0762l5l.execute-api.us-east-1.amazonaws.com/dev/requestQuote", {
      method: "PUT",
      mode: "cors",
      headers: {
      },
      body: JSON.stringify({
        uuid: uuid,
        name: name,
        email: email,
        projectName: projectName
      })
    }).then((result) => {
      // console.log("saved");
      this.showThankYou();

    }, (result) => {
      alert("Sorry something went wrong.");
    });
  }

  shareEstimate = (name, email, note) => {
    const uuid = window.location.pathname.replace("/", "");

    window.fetch("https://hsg0762l5l.execute-api.us-east-1.amazonaws.com/dev/shareEstimate", {
      method: "PUT",
      mode: "cors",
      headers: {
      },
      body: JSON.stringify({
        uuid: uuid,
        name: name,
        email: email,
        note: note
      })
    }).then((result) => {
      this.showThankYou();

    }, (result) => {
      alert("Sorry something went wrong.");
    });
  }

  setStep = (step) => {
    this.setState({step: step},
      this.updateEstimate);
  }

  setAnswerForStep = (step, answer) => {
    if (estimates.steps[step] === "Product Type") {
      const details    = estimates.sections[estimates.steps[step]];
      this.setState({"product_type": details.answers[answer]});
    }

    this.setState(state => {
      const answer_for_step = this.state.answer_for_step.map((value, index)  => {
        if (index === step) {
          return answer;
        } else {
          return value;
        }
      });

      return {
        answer_for_step,
      }
    },
      this.updateEstimate
    );
  }

  setOptionForStep = (step, option) => {
    this.setState(state => {
      const option_for_step = this.state.option_for_step.map((value, index)  => {
        if (index === step) {
          return option;
        } else {
          return value;
        }
      });

      return {
        option_for_step,
      }
    },
      this.updateEstimate
    );
  }

  setCategoryForStep = (step, answer, category) => {
    const estimateStep = estimates.steps[step];
    const section = estimates.sections[estimateStep];
    const categories = section.categories;
    var categoryName = undefined;
    if (categories !== undefined) {
      const categoryData = categories[this.state.product_type];
      if (categoryData !== undefined) {
        categoryName = categoryData[answer];
      }
    }

    const rfqRequiredFor = section.rfqRequiredFor;
    if (rfqRequiredFor !== undefined) {
      if (rfqRequiredFor.includes(categoryName)) {
        this.setState({rfqRequired: true});
      } else {
        this.setState({rfqRequired: false});
      }
    }

    this.setState(state => {
      const category_for_step = this.state.category_for_step.map((value, index)  => {
        if (index === step) {
          return answer;
        } else {
          return value;
        }
      });

      return {
        category_for_step,
      }
    },
      this.updateEstimate);
  }

  setSimilarForStep = (step, similar) => {
    this.setState(state => {
      const similar_for_step = this.state.similar_for_step.map((value, index)  => {
        if (index === step) {
          return similar;
        } else {
          return value;
        }
      });

      return {
        similar_for_step,
      }
    },
      this.updateEstimate
    );
  }

  addItemForStep = (step, item) => {
    this.setState(state => {
      const items_for_step = this.state.items_for_step.map((items, index) => {
        if (index === step) {
          // this stems stupidly inefficient but also seems like what you need to do
          var newItems = new Set(items);
          newItems.add(item);
          return newItems;
        } else {
          return items;
        }
      });

      return {
        items_for_step
      }
    },
      this.updateEstimate);
  }

  removeItemFromStep = (step, item) => {
    this.setState(state => {
      const items_for_step = this.state.items_for_step.map((items, index) => {
        if (index === step) {
          // this stems stupidly inefficient but also seems like what you need to do
          var newItems = new Set(items);
          newItems.delete(item);
          return newItems;
        } else {
          return items;
        }
      });

      return {
        items_for_step
      }
    },
      this.updateEstimate);
  }

  multiplier = () => {
    var multiplier = 1.0;

    for (var stepNumber=0;stepNumber<estimates.steps.length;stepNumber++) {
      const step          = estimates.steps[stepNumber];
      const details       = estimates.sections[step];
      const options       = details.options
      const optionNumber  = this.state.option_for_step[stepNumber];
      if (optionNumber !== undefined && details !== undefined) {
        const option        = options[optionNumber];
        const subDetails = details.details;
        if (option !== undefined && subDetails !== undefined) {
          // subDetails[option];
          const optionDetails = subDetails[option];
          if (optionDetails !== undefined) {
            const optionMultiplier = optionDetails.multiplier;
            if (optionMultiplier !== undefined) {
              multiplier *= optionMultiplier;
            }
          }
        }
      }
    }

    return multiplier;
  }

  itemCostTimeRange = (stepNumber, item) => {
    var minCost  = 0
    var minHours = 0

    // const itemsForStep = this.state.items_for_step[step];
    const step = estimates.steps[stepNumber];
    const section = estimates.sections[step];

    if (section === undefined) {
      return {
        minCost: minCost,
        minHours: minHours,
      }
    }

    const items = section.items;

    if (item instanceof Array) {
      // console.log("item is an array");
    } else {
      const details = items[item];
      if (details === undefined) {

        return {
          minCost: minCost,
          minHours: minHours,
        }
      }

      const detailsForProductType = details[this.state.product_type];
      var minHoursForItem = 0;
      var minRateForItem  = 0;

      if (detailsForProductType === undefined) {
        if (details.minHours !== undefined) {
          minHoursForItem = details.minHours;
        }

        if (details.minRate !== undefined) {
          minRateForItem = details.minRate;
        }

      } else {
        if (detailsForProductType.minHours !== undefined) {
          minHoursForItem = detailsForProductType.minHours;
        }

        if (detailsForProductType.minRate !== undefined) {
          minRateForItem = detailsForProductType.minRate;
        }
      }

      minCost = minHoursForItem * minRateForItem;
    }

    minCost *= this.multiplier();

    return {
      minCost: minCost,
      minHours: minHours
     }
  }

  formattedCost = (cost) => {
    var formattedCost = (cost/1000).toLocaleString('en', {maximumFractionDigits: 1})+"k";
    if (cost/1000 >= 1000) {
      formattedCost = (cost/1000000).toLocaleString('en', {maximumFractionDigits: 1})+"M";
    }

    return (formattedCost)
  }

  itemRange = (step, item) => {
    const costTimeRange = this.itemCostTimeRange(step, item);
    if (costTimeRange.minCost > 0) {
      const formattedCost = this.formattedCost(costTimeRange.minCost);

      return formattedCost + "+";
    }

    return "TBD";
  }

  updateEstimate = () => {
    var minCost  = 0
    var minHours = 0

    // iterate through all steps and items and sum min/max costs and hours
    for (var stepNumber=0;stepNumber<estimates.steps.length;stepNumber++) {
      const step = estimates.steps[stepNumber];
      const section = estimates.sections[step];

      if (section === undefined) {
        continue;
      }

      const items = section.items;

      if (items === undefined) {
        continue;
      }

      const itemsForStep = Array.from(this.state.items_for_step[stepNumber]);
      for (var itemNumber=0;itemNumber<itemsForStep.length;itemNumber++) {
        const item = itemsForStep[itemNumber];

        const itemRange = this.itemCostTimeRange(stepNumber, item);

        minCost += itemRange.minCost;
      };
    };

    if (minCost === 0)  {
      return
    }

    const formatted_cost = this.formattedCost(minCost);

    this.setState(
      {
        minCost: formatted_cost,
        minHours: minHours,
      }
    );
  }

  showSimilar = (stepNumber) => {
    this.setState({
      step: stepNumber,
      showDialog: true,
      showSimilar: true
    });
  }

  showDialog = () => {
    this.setState({showDialog: true});
  }


  hideDialog = () => {
    this.setState({
      showDialog: false,
      share: false,
      getQuote: false,
      showSimilar: false,
      showLogin: false,
      thankYou: false
    });
  }

  escFunction = () => {
    this.hideDialog();
  }

  componentDidMount(){
    let uuid = window.location.pathname.replace("/", "");

    this.setState({uuid: uuid});
    if (window.location.pathname === "/") {
      window.location.pathname = "/" + uuidv4();
    } else {
      this.load();
    }

    document.addEventListener("keydown", (e) => {if (e.which === 27) {this.escFunction()}} , false);
  }
  //
  // componentWillUnmount(){
  //   document.removeEventListener("keydown", this.escFunction, false);
  // }

  componentDidUpdate = (props, state) => {
    this.save()
  }

  render() {
    return (
      <div className="App">
        <Header
          steps      = {estimates.steps}
          step       = {this.state.step}
          setStep    = {this.setStep}
        />
        <Main
          minCost            = {this.state.minCost}
          minHours           = {this.state.minHours}
          estimates          = {estimates}
          steps              = {estimates.steps}
          step               = {this.state.step}
          setStep            = {this.setStep}
          answer_for_step    = {this.state.answer_for_step}
          option_for_step    = {this.state.option_for_step}
          product_type       = {this.state.product_type}
          setAnswerForStep   = {this.setAnswerForStep}
          setOptionForStep   = {this.setOptionForStep}
          category_for_step  = {this.state.category_for_step}
          setCategoryForStep = {this.setCategoryForStep}
          items_for_step     = {this.state.items_for_step}
          itemRange          = {this.itemRange}
          addItemForStep     = {this.addItemForStep}
          removeItemFromStep = {this.removeItemFromStep}
          rfqRequired        = {this.state.rfqRequired}
          showSimilar        = {this.showSimilar}
          showDialog         = {this.showDialog}
          getQuote           = {this.getQuote}

        />
        <Footer
          minCost      = {this.state.minCost}
          minHours     = {this.state.minHours}
          product_type = {this.state.product_type}
          rfqRequired  = {this.state.rfqRequired}
          estimates    = {this.props.estimates}
          getQuote     = {this.getQuote}
          share        = {this.share}
        />

        <Dialog
          step               = {this.state.step}
          steps              = {estimates.steps}
          estimates          = {estimates}
          showDialog         = {this.state.showDialog}
          hideDialog         = {this.hideDialog}
          thankYou           = {this.state.thankYou}
          showThankYou       = {this.showThankYou}
          showSimilar        = {this.state.showSimilar}
          showLogin          = {this.state.showLogin}
          answer_for_step    = {this.state.answer_for_step}
          category_for_step  = {this.state.category_for_step}
          similar_for_step   = {this.state.similar_for_step}
          setSimilarForStep  = {this.setSimilarForStep}
          getQuote           = {this.state.getQuote}
          share              = {this.state.share}
          shareEstimate      = {this.shareEstimate}
          sendRFQ            = {this.sendRFQ}
        />
      </div>
    );
  }
}

export default App;
